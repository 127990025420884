


























































































import { MutationPayload } from 'vuex';
import router from '@/router';
import store from '@/store';
import { getVisitorById } from '@/api/visitors';
import { getExhibitorById } from '@/api/exhibitors';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  data() {
    return {
      redirectUrl: process.env.VUE_APP_REDIRECT_URL,
      event: 0,
    };
  },
  setup() {
    const {
      translations,
      contextEvent,
      userManages,
      relatedVisitors,
      relatedExhibitors,
      userAdmin,
      userRolesSet,
    } = useContext();

    const event = parseInt(router.currentRoute.params.event, 10);

    const userManagesThisEvent = computed(
      () =>
        userManages.value &&
        userManages.value.length > 0 &&
        userManages.value.some((eventId) => eventId === event),
    );

    const userManagesExhibitorInThisEvent = computed(
      () =>
        relatedExhibitors.value &&
        relatedExhibitors.value.some((exhibitor) => exhibitor.event === event),
    );

    const userVisitsThisEvent = computed(
      () =>
        relatedVisitors.value && relatedVisitors.value.some((visitor) => visitor.event === event),
    );

    return {
      translations,
      contextEvent,
      userManages,
      relatedVisitors,
      relatedExhibitors,
      userAdmin,
      userRolesSet,
      userManagesThisEvent,
      userManagesExhibitorInThisEvent,
      userVisitsThisEvent,
    };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    if (this.userRolesSet) {
      if (this.event) {
        this.handleUserRoles();
      } else {
        const unsubscribe = store.subscribe((mutation: MutationPayload) => {
          if (mutation.type === 'setContextEvent') {
            this.handleUserRoles();
            unsubscribe();
          }
        });
      }
    } else {
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setRoles') {
          this.handleUserRoles();
          unsubscribe();
        }
      });
    }
  },
  methods: {
    handleUserRoles() {
      if (this.userManagesExhibitorInThisEvent) {
        const exhibitorsInEvent = this.relatedExhibitors.filter(
          (exhibitor) => this.event === exhibitor.event,
        );
        if (exhibitorsInEvent.length === 1 && exhibitorsInEvent[0].id) {
          getExhibitorById(exhibitorsInEvent[0].id).then((response) => {
            store.commit('setContextExhibitor', response.data);
          });
          router.push(`/event/${this.event}/manage-exhibitor/${exhibitorsInEvent[0].id}`);
        } else {
          router.push(`/event/${this.event}/manage-exhibitors`);
        }
      } else if (this.userVisitsThisEvent) {
        const visitorsInEvent = this.relatedVisitors.filter(
          (visitor) => this.event === visitor.event,
        );
        if (visitorsInEvent.length === 1 && visitorsInEvent[0].id) {
          getVisitorById(visitorsInEvent[0].id).then((response) => {
            store.commit('setContextVisitor', response.data);
          });
          router.push(`/event/${this.event}`);
        } else {
          router.push(`/event/${this.event}/manage-visitors`);
        }
      } else if (this.userAdmin) {
        store.commit('setContextVisitor', {
          id: 0,
          name: 'Admin',
          email: 'admin@beamian.com',
          event: this.event,
        });
      } else if (!this.userManagesThisEvent) {
        router.push(`/event/${this.event}`);
      }
    },
  },
});
